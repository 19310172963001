<template>
  <div>
    <div
      v-if="isMobileOrTablet"
      class="flex justify-center w-full min-h-32"
      :style="`background-color: ${blok?.backgroundcolor?.color ?? '#3cbeaf'}`"
    >
      <div
        v-if="isMobileOrTablet"
        class="flex w-full text-xs px-2 items-center justify-between md:justify-start md:space-x-5"
      >
        <component
          v-for="(item, index) in blok.left.slice(0, 2)"
          :key="index"
          :is="item.component"
          :blok="item"
        />
      </div>
      <div class="hidden md:flex items-center space-x-5 mr-2">
        <component
          v-for="(item, index) in blok.right"
          :key="index"
          :is="item.component"
          :blok="item"
        />
      </div>
    </div>
    <div
      v-else
      class="w-full min-h-27 flex items-center justify-center"
      :style="`background-color: ${blok?.backgroundcolor?.color ?? '#3cbeaf'}`"
    >
      <div
        class="max-w-1400 w-full mx-auto flex items-center justify-between px-7 lg:px-2"
      >
        <div
          class="flex items-center space-x-5 pr-4 flex-auto overflow-hidden py-1 mr-5"
        >
          <component
            v-for="(item, index) in blok.left"
            :key="index"
            :is="item.component"
            :blok="item"
          />
        </div>
        <div class="flex items-center space-x-5">
          <component
            v-for="(item, index) in blok.right"
            :key="index"
            :is="item.component"
            :blok="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  blok: Object,
})

const { isMobileOrTablet } = useDevice()
</script>
